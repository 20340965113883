import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { results, CopyButton, InternalLink } from '@mdc/ui';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Authenticator, fileSizeConvert, dateTimeService, localStorage, InternalNavigation } from '@mdc/services';
import { RESULT_TEXTS, ROUTES, SANITIZATION_FILE_TYPES, SCAN_FILE_STATUS, SCAN_SANITIZED_STATUS } from '@mdc/constants';

import './Overview.scss';

const { Alert, SandboxScore, Score, DataSanitization, FileBasedVulnerability, FileOverview, CodeSnippet, ScorePlaceholder } = results;

const SKIP_SANITIZED_WARNING_MESSAGE = [12, 15];
const LAST_UPLOAD_ID_KEY = 'lastUploadId';
/* eslint-disable camelcase */
/* eslint-disable no-useless-escape */
const Overview = (
    {
        type,
        id,
        action,
        results,
        sanitizedResult,
        downloadSanitized,
        sandboxResults,
        vulnerabilityResults,
        upVote,
        downVote,
        resultsLoading,
        scanning,
        progress,
        fileImage,
        reportVulnerability,
        reportVulnerabilityResult,
        isSignIn,
        peInfoResults,
        esKey
    }) => {

    const { ready, t, i18n } = useTranslation();
    const extracted = results?.extracted_files?.files_in_archive;
    const scanResults = results?.scan_results ? results.scan_results : {};
    const lang = i18n?.language?.split('-')[0] || 'en';

    const resultTexts = useMemo(() => RESULT_TEXTS(t), [t]);

    const apiKey = Authenticator.apikey;

    const esKeyAlert = useMemo(() => {
        let message;
        if (!resultsLoading && esKey && !scanning) {
            message = <span>
                {t('Your file has been encrypted, please save this ESKey')}
                {' '}
                <span className='esKey'>
                    {esKey}
                    <CopyButton icon={'clone'} data={esKey} copyText={t('click to copy')} copiedText={t('copied')} />
                </span>
                {' '}
                {t('in order to decrypt the file')}
            </span>;
        }

        if (message) {
            return <Alert message={message} type='info' />;
        }

        return null;
    }, [results, esKey, resultsLoading, scanning, scanResults, t]);

    const sandboxAvailableAlert = useMemo(() => {
        if (resultsLoading || scanning || sandboxResults?.status === 'loading') {
            return;
        }

        if ((typeof sandboxResults?.results === 'undefined') && sandboxResults?.status !== 'loading' && sandboxResults?.status !== 'processing') {
            const message = <span>
                {t('A more in depth analysis can be performed using our Sandbox technology.')}
                {' '}
                {t('Click the')}
                <i className={'icon-settings'} />
                {t('button and resubmit the file with the ')}
                <b>{('Sandbox ')}</b>
                {t('option check to get the dynamic analysis results')}
                {'.'}
            </span>;

            return <Alert message={message} type='info' />;
        }
    }, [scanResults, resultsLoading, scanning, sandboxResults]);

    const fileTypeMismatchAlert = useMemo(() => {
        if (resultsLoading || scanning) {
            return;
        }

        const fileExtension = typeof results?.file_info?.file_type_extension === 'string' ? results.file_info.file_type_extension : null;
        const fileName = results?.file_info?.display_name?.toLowerCase();
        // eslint-disable-next-line security/detect-unsafe-regex
        const extensionRegex = /(?:\.([^.]+))?$/;

        if (fileExtension && fileExtension !== extensionRegex.exec(fileName)[1]) {
            const message = <span>
                {t('There is a mismatch between this file\'s type and its extension.')}
                {' '}
                {t('The file type is {{fileType}}', { fileType: fileExtension || results?.file_info?.file_type_description })}
                {'.'}
            </span>;

            return <Alert message={message} type='warning' />;
        }

        return null;
    }, [results, resultsLoading, scanning, t]);

    const newerAlert = useMemo(() => {
        let message;
        if (!resultsLoading && results?.newer && !scanning) {
            // If only scanned with CDR option or SANDBOX option
            if ((scanResults?.scan_details && Object.keys(scanResults?.scan_details).length === 0 && scanResults?.scan_details?.constructor === Object) && scanResults?.scan_all_result_i === 10 && results?.process_info?.profile === 'cdr' ||
                ((scanResults?.scan_all_result_i === 10 || scanResults?.scan_all_result_i === 5) && results?.process_info?.profile === undefined)) {
                message = <span>
                    <InternalLink to={`${ROUTES.results.href}${ROUTES.results.file}/${results.newer}/regular/overview`}>{t('Click here')}</InternalLink>
                    {' '}
                    {t('to view Multiscanning results for this file')}
                </span>;
            } else {
                message = <span>
                    {t('We have a newer analysis for this file')}
                    {' '}
                    <InternalLink to={`${ROUTES.results.href}${ROUTES.results.file}/${results.newer}/regular/overview`}>{t('Click here')}</InternalLink>
                    {' '}
                    {t('to view the latest results')}
                </span>;
            }
        }

        if (message) {
            return <Alert message={message} type='warning' />;
        }

        return null;
    }, [results, resultsLoading, scanning, scanResults, t]);

    const infectedArchiveAlert = useMemo(() => {
        const extractedDetectedBy = extracted && extracted.reduce((prevEngine, currentEngine) => (
            prevEngine.detected_by < currentEngine.detected_by ? currentEngine : prevEngine
        ))?.detected_by;
        const archiveDetectedBy = scanResults?.total_detected_avs;

        if (extractedDetectedBy > 0 && archiveDetectedBy > 0) {
            const message = t('The archive was detected by {{archiveNumberOfEngines}} engine(s) as infected, and contains files detected as infected by up to {{numberOfEngines}} engine(s)', { archiveNumberOfEngines: archiveDetectedBy, numberOfEngines: extractedDetectedBy });
            return <Alert message={message} type='warning' />;
        }

        return null;
    }, [scanResults, extracted, t]);

    const scanAlert = useMemo(() => {
        if (!resultsLoading && !scanning && results?.scan_results?.scan_all_result_i && Object.prototype.hasOwnProperty.call(resultTexts.scanAlertInfo, `i${results.scan_results.scan_all_result_i}`)) {
            return <Alert message={resultTexts.scanAlertInfo[`i${results.scan_results.scan_all_result_i}`]} type='danger' />;
        }

        return null;
    }, [results, resultsLoading, scanning, resultTexts]);

    const sanitizedFailReason = useMemo(() => {
        if (ready) {
            return {
                'invalid file structure': t('File is corrupted or it does not match specification'),
                'unsupported version': t('MS Office file is created with format before MS Office 97 or DWG file is created with AutoCAD before version 2004'),
                'exceeded file size': t('This file size is bigger than the configured file size'),
                'password protected': t('Document needs a password to unlock'),
                'failed to sanitize': t('Other failures during sanitization, should not happen')
            };
        }

        return {};
    }, [ready, t]);

    const deepCdrAlert = useMemo(() => {
        if (sanitizedResult?.result === SCAN_SANITIZED_STATUS.error && results) {
            let message = t('The file is not sanitizable');

            if (sanitizedResult.reason !== 'File is not sanitizable') {
                const failCategory = results.process_info?.post_processing?.sanitization_details?.failure_category;
                if (Object.prototype.hasOwnProperty.call(sanitizedFailReason, failCategory)) {
                    message = `${t('The file was not sanitized')} ${sanitizedFailReason[failCategory]}`;
                } else if (SKIP_SANITIZED_WARNING_MESSAGE.includes(results?.scan_results?.scan_all_result_i)) {
                    // If there is no 'failure_category' value and file can't scan due to "encrypted/password-protected archives"
                    // Don't need to display deep CDR Alert
                    return null;
                }
            }

            return <Alert message={message} type='warning' />;
        }

        return null;
    }, [results, sanitizedResult, sanitizedFailReason, t]);

    const dlpAlert = useMemo(() => {
        if (!resultsLoading && !scanning && results?.dlp_info?.errors?.scan === 'File type not supported') {
            return <Alert message={t('The file is not supported by DLP')} type='warning' />;
        }
    }, [resultsLoading, scanning, results, t]);

    const archiveInfectedAlert = useMemo(() => {
        if (!resultsLoading && !scanning && results?.scan_results?.total_detected_avs === 0 && results?.extracted_files?.files_in_archive?.some((file) => file.scan_result_i === 1)) {
            return <Alert message={t('The archive has been marked as infected because the files in the archives are detected as infected')} type='warning' />;
        }
    }, [resultsLoading, scanning, results, t]);

    const gotReputationPointAlert = useMemo(() => {
        // If file infected detected by 4 or more engine, and this is the first time scan
        if (!resultsLoading && !scanning && results?.scan_results?.scan_all_result_i === 1 && results?.scan_results?.total_detected_avs >= 4 && (results?.scan_result_history_length === 1 || !results?.scan_result_history_length)) {
            // User is logged in, and data id match with last upload file id by this user
            if (isSignIn && localStorage.getItem(LAST_UPLOAD_ID_KEY) === results?.data_id) {
                const message = <span dangerouslySetInnerHTML={{ __html: t('You have gained 1 reputation point for submitting an infected file! Check out our <a href=\"/leaderboard\" rel=\"noopener noreferrer\" target=\"_blank\">leaderboard</a> to see where you rank') }} />;
                return <Alert message={message} type='info' />;
            }
        }
    }, [isSignIn, resultsLoading, scanning, results, t]);

    const voteScore = useMemo(() => {
        if (upVote || downVote) {
            return Math.round(downVote * 100 / (downVote + upVote));
        }
        return undefined;
    }, [upVote, downVote, resultsLoading, scanning]);

    const scoreMetascan = useMemo(() => {
        const getScanProcessText = () => {
            if (scanning === SCAN_FILE_STATUS.inQueue) {
                return t('In Queue');
            } else if (scanning === SCAN_FILE_STATUS.inProgress) {
                return t('In Progress [{{progress}}%]', { progress });
            }

            return '';
        };

        const scanProcessText = resultsLoading ? t('Loading') : getScanProcessText();

        const getScoreData = () => {
            if (!scanResults?.total_avs || sanitizedResult?.reason === 'Password Protected Document' || scanResults?.scan_all_result_a === 'Aborted') {
                return {
                    info: t('No multiscan performed'),
                    detection: 'notPerformed'
                };
            }

            return scanResults?.total_detected_avs > 0 || scanResults?.scan_all_result_i === 1 ? {
                info: t('Threats detected'),
                detection: 'detected'
            } : {
                info: t('No threats detected'),
                detection: 'notDetected'
            };
        };

        return <Score
            category={t('Metascan')}
            score={scanResults.total_detected_avs || 0}
            info={getScoreData().info}
            detection={getScoreData().detection}
            link={<InternalLink to='/store'>{t('Upgrade limits')}</InternalLink>}
            numberOfEngines={scanResults.total_avs || 0}
            scoreText={t('ENGINES')}
            buttonText={t('Get full report')}
            buttonDisabled={!scanResults.total_avs}
            onButtonClick={() => {
                if (scanResults.total_avs) {
                    InternalNavigation.navigation(`${ROUTES.results.href}/${type}/${id}/${action}/multiscan`);
                }
            }}
            isProcessing={resultsLoading || !!scanning}
            processImgSrc={fileImage.processing.publicURL}
            processingText={scanProcessText}
        />;
    }, [resultsLoading, scanning, progress, results, type, id, action, sanitizedResult, t]);

    const sandboxAlert = useMemo(() => {
        if (sandboxResults?.needEmailSubmit) {
            return <Alert message={t('To continue using OPSWAT sandbox, please provide your contact information')} type='warning' />;
        }
    }, [sandboxResults, t]);

    const scoreSandbox = useMemo(() => {
        const isButtonValid = sandboxResults?.results && sandboxResults?.results != 'Aborted';

        return (
            <SandboxScore
                category={t('Sandbox Score')}
                link={t('<a href=\"https://docs.opswat.com/filescan\" rel=\"noopener noreferrer\" target=\"_blank\">Sandbox documentation</a>')}
                isFileSupport={true}
                buttonText={t('View summary')}
                onButtonClick={() => {
                    if (isButtonValid) {
                        InternalNavigation.navigation(`/results/${type}/${id}/${action}/sandbox/summary`);
                    }
                }}
                resultsLoading={resultsLoading}
                scanning={scanning}
                buttonDisabled={!isButtonValid}
                sandboxResults={sandboxResults}
                processImgSrc={fileImage.processing.publicURL}
                hasButton={true}
                fileImage={fileImage}
            />
        );
    }, [resultsLoading, scanning, progress, results, sandboxResults, voteScore, type, id, action, t]);

    const scoreCommunity = useMemo(() => {
        const getScoreInfo = (voteScore) => {
            if (voteScore !== undefined) {
                return {
                    score: 100 - voteScore,
                    badge: t('Clean'),
                    badgeColor: 'success'
                };
            }

            return {
                score: -1,
                badge: undefined,
                badgeColor: null
            };
        };

        return <Score
            category={t('Community Insight')}
            score={voteScore >= 50 ? voteScore : getScoreInfo(voteScore).score}
            badge={voteScore >= 50 ? t('Malicious') : getScoreInfo(voteScore).badge}
            badgeColor={voteScore >= 50 ? 'danger' : getScoreInfo(voteScore).badgeColor}
            info={t('User votes')}
            buttonText={t('View leaderboards')}
            isPercentage={true}
            onButtonClick={() => {
                InternalNavigation.navigation('/leaderboard');
            }
            }
            link={<InternalLink to={`${ROUTES.results.href}/${type}/${id}/${action}/community`}>{t('Check out our community')}</InternalLink>}
        />;
    }, [voteScore, type, id, action, t, upVote, downVote]);

    const sanitizationDOM = useMemo(() => {
        if (!results || (sanitizedResult && sanitizedResult.result === SCAN_SANITIZED_STATUS.processing) || resultsLoading || scanning) {
            return <ScorePlaceholder title={t('Deep CDR')} />;
        }

        const sanitizedFileLink = sanitizedResult?.data_id ?
            <InternalLink to={`${ROUTES.results.href}${ROUTES.results.file}/${sanitizedResult?.data_id}/regular/overview`}>{t('Sanitized file report')}</InternalLink> : null;


        let sanitizedResults = sanitizedResult?.result ? [0, 0] : undefined;
        if (results.process_info?.post_processing?.sanitization_details?.details && sanitizedResult?.result !== SCAN_SANITIZED_STATUS.error) {
            const { details } = results.process_info.post_processing.sanitization_details;
            const sanitizedActions = Array.isArray(details) && details.filter((detail) => detail.action === 'sanitized')
                .reduce((countSum, currentValue) => countSum + (currentValue.count || 0), 0);
            const sanitized = sanitizedActions || 0;

            const removedActions = Array.isArray(details) && details.filter((detail) => detail.action === 'removed')
                .reduce((countSum, currentValue) => countSum + (currentValue.count || 0), 0);
            const removed = removedActions || 0;
            sanitizedResults = [sanitized, removed];
        }

        return <DataSanitization
            results={sanitizedResults}
            onViewClick={() => { InternalNavigation.navigation(`/results/${type}/${id}/${action}/sanitization`); }}
            sanitizedResult={sanitizedResult}
            sanitizedFileLink={sanitizedFileLink}
            downloadSanitizedVersion={downloadSanitized}
            detailLink={t('<a href=\"https://www.opswat.com/technologies/data-sanitization\" rel=\"noopener noreferrer\" target=\"_blank\">Learn more about Data Sanitization</a>')}
            isFileTypeSupported={SANITIZATION_FILE_TYPES.includes(results?.file_info?.file_type_extension?.toLowerCase())}
            invalidFileImg={<img src={fileImage.invalid.publicURL} alt={'invalid'} title='Invalid' />}
            availableFileImg={<img src={fileImage.available.publicURL} alt={'available'} title='Available' />} />;

    }, [results, resultsLoading, sanitizedResult, downloadSanitized, scanning, type, id, action, fileImage, t]);

    const vulnerabilityDOM = useMemo(() => {
        if (vulnerabilityResults === undefined || !results || resultsLoading || scanning) {
            return <ScorePlaceholder title={t('File-based Vulnerability Assessment')} />;
        }

        let isFileTypeSupport = !!results.file_info?.file_type_category && ['D', 'G', 'T', 'M', 'Z'].indexOf(results.file_info.file_type_category.toUpperCase()) === -1;

        return <FileBasedVulnerability
            severityIndex={vulnerabilityResults ? vulnerabilityResults.severity_index : 0}
            severity={vulnerabilityResults ? vulnerabilityResults.severity : ''}
            reportVulnerabilityClick={reportVulnerability}
            viewReportClick={() => { InternalNavigation.navigation(`/results/${type}/${id}/${action}/vulnerabilities`); }}
            reportVulnerabilityResult={reportVulnerabilityResult}
            isSignIn={isSignIn}
            isFileTypeSupport={isFileTypeSupport}
            isDisplayedOnOverview={true}
        />;
    }, [results, vulnerabilityResults, resultsLoading, scanning, type, id, action, reportVulnerabilityResult, t]);

    const fileInfoDOM = useMemo(() => {
        const fileInfoResult = results?.file_info;
        const scanResults = results?.scan_results;
        const allColumns = [];

        fileInfoResult?.company_name && allColumns.push({
            'column': t('Vendor'),
            'data': fileInfoResult.company_name
        });

        fileInfoResult?.product_name && allColumns.push({
            'column': t('Product'),
            'data': fileInfoResult.product_name
        });

        fileInfoResult?.file_type_category && allColumns.push({
            'column': t('Category'),
            'data': fileInfoResult.file_type_category
        });

        fileInfoResult?.legal_copyright && allColumns.push({
            'column': t('Copyright'),
            'data': fileInfoResult.legal_copyright
        });

        fileInfoResult?.file_type_description && allColumns.push({
            'column': t('File type'),
            'data': fileInfoResult.file_type_description
        });

        (typeof fileInfoResult?.file_type_extension === 'string') && fileInfoResult.file_type_extension && allColumns.push({
            'column': t('File Extension'),
            'data': fileInfoResult.file_type_extension
        });

        fileInfoResult?.file_size && allColumns.push({
            'column': t('File size'),
            'data': fileSizeConvert(fileInfoResult.file_size) + ' ' + t('[{{fileSize}} bytes]', { fileSize: fileInfoResult.file_size })
        });

        fileInfoResult?.upload_timestamp && allColumns.push({
            'column': t('Uploaded'),
            'data': dateTimeService.getDateTimeString(fileInfoResult.upload_timestamp, lang) + ' ' + t('[ {{dateHumanized}} ago ]', { dateHumanized: dateTimeService.dateHumanized(fileInfoResult.upload_timestamp, lang) })
        });

        scanResults?.start_time && allColumns.push({
            'column': t('Scanned'),
            'data': dateTimeService.getDateTimeString(scanResults.start_time, lang) + ' ' + t('[ {{dateHumanized}} ago ]', { dateHumanized: dateTimeService.dateHumanized(scanResults.start_time, lang) })
        });

        scanResults?.total_time && allColumns.push({
            'column': t('Duration'),
            'data': dateTimeService.getDurationHumanized(scanResults?.total_time, lang)
        });

        fileInfoResult?.md5 && allColumns.push({
            'column': t('MD5'),
            'data': fileInfoResult.md5,
            'iconAfter': 'clone'
        });

        fileInfoResult?.sha1 && allColumns.push({
            'column': t('SHA1'),
            'data': fileInfoResult.sha1,
            'iconAfter': 'clone'
        });

        fileInfoResult?.sha256 && allColumns.push({
            'column': t('SHA256'),
            'data': fileInfoResult.sha256,
            'iconAfter': 'clone',
        });

        peInfoResults?.imphash && allColumns.push({
            'column': t('Imphash'),
            'data': peInfoResults?.imphash,
            'iconAfter': 'clone',
        });

        let firstColumn;
        let secondColumn;

        if (allColumns.length > 0) {
            firstColumn = allColumns.slice(0, Math.round(allColumns.length / 2));
            secondColumn = allColumns.slice(Math.round(allColumns.length / 2), allColumns.length);
        }

        return <FileOverview title={t('File Overview')} firstColumnData={firstColumn} lastColumnData={secondColumn} />;
    }, [results, resultsLoading, scanning, t, lang]);

    const codeDOM = useMemo(() => {
        return <CodeSnippet apiKey={apiKey} id={id} type={action === 'hash' ? 'hash' : 'file'} />;
    }, [apiKey, id, action]);

    if (!ready) {
        return null;
    }

    return <div className='overview'>
        {fileTypeMismatchAlert}
        {sandboxAvailableAlert}
        {esKeyAlert}
        {newerAlert}
        {infectedArchiveAlert}
        {scanAlert}
        {deepCdrAlert}
        {dlpAlert}
        {sandboxAlert}
        {archiveInfectedAlert}
        {gotReputationPointAlert}
        <div className='overviewBody'>
            <Row>
                {scoreMetascan}
                {scoreSandbox}
                {scoreCommunity}
            </Row>
            <Row>
                {sanitizationDOM}
            </Row>
            <Row>
                {vulnerabilityDOM}
            </Row>
            <Row>
                {fileInfoDOM}
            </Row>
            <Row>
                {codeDOM}
            </Row>
        </div>
    </div>;
};

Overview.propTypes = {
    type: PropTypes.string,
    id: PropTypes.string,
    action: PropTypes.string,
    results: PropTypes.object,
    downloadSanitized: PropTypes.func.isRequired,
    sanitizedResult: PropTypes.object,
    sandboxResults: PropTypes.object,
    vulnerabilityResults: PropTypes.any,
    upVote: PropTypes.number,
    downVote: PropTypes.number,
    resultsLoading: PropTypes.bool,
    scanning: PropTypes.string,
    progress: PropTypes.number,
    fileImage: PropTypes.object.isRequired,
    reportVulnerability: PropTypes.func.isRequired,
    reportVulnerabilityResult: PropTypes.string,
    isSignIn: PropTypes.bool,
    peInfoResults: PropTypes.object,
    esKey: PropTypes.string
};

export default Overview;
