import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { CopyButton, PlainTable, results } from '@mdc/ui';
import { dayjs, fileSizeConvert } from '@mdc/services';
import { useTranslation } from 'react-i18next';
import ContentLoader from 'react-content-loader';
import Image from './Image';
import ExpandableRow from '../dynamic-analysis/ExpandableRow';
import FileDetailsSection from '../sandbox-pages/file-details/FileDetailsSection';

import './AddressAnalysis.scss';

const { ScoreHeader } = results;

const requestColumns = [
    {
        header: 'URL:',
        accessor: 'url',
    },
    {
        header: 'Method:',
        accessor: 'method',
    },
    {
        header: 'Type:',
        accessor: 'type',
    },
    {
        header: 'Time:',
        accessor: 'time',
    },
    {
        header: 'Size:',
        accessor: 'size',
    },
];

const certificateColumns = [
    {
        Header: 'Subject',
        accessor: 'subjectName',
    },
    {
        Header: 'Issuer',
        accessor: 'issuer',
    },
    {
        Header: 'Valid from',
        accessor: 'validFrom',
    },
    {
        Header: 'Valid to',
        accessor: 'validTo',
    },
];

const SCORE_COLOR_CODES = {
    '-1': 'benign',
    0: 'unknow',
    0.05: 'unknow',
    0.1: 'informational',
    0.15: 'informational',
    0.2: 'informational',
    0.25: 'informational',
    0.3: 'informational',
    0.35: 'informational',
    0.4: 'suspicious',
    0.45: 'suspicious',
    0.5: 'suspicious',
    0.55: 'suspicious',
    0.6: 'suspicious',
    0.65: 'suspicious',
    0.7: 'likelyMalicious',
    0.75: 'likelyMalicious',
    0.8: 'likelyMalicious',
    0.85: 'likelyMalicious',
    0.9: 'malicious',
    0.95: 'malicious',
    1: 'malicious',
};

const formatScore = (score) => {
    if (score == -1) {
        return score;
    }

    if (Number.isNaN(score)) {
        return 0;
    }

    return score * 100;
};

const getScoreColor = (score) => {
    if (score == -1) {
        return SCORE_COLOR_CODES['-1'];
    }
    const number = Number.isNaN(score) ? 0 : Math.abs(score);
    const roundedNumber = Math.ceil(number * 20) / 20;
    const colorCode = roundedNumber.toString();

    return SCORE_COLOR_CODES[colorCode];
};

const getBadgeColor = (status) => {
    const statusCode = String(status);
    if (statusCode.startsWith('2')) {
        return 'badge-success';
    }

    return 'badge-error';
};

const getResources = (reports) => {
    const reportList = Array.isArray(reports) ? reports : [];
    const resourceList = reportList.reduce((acc, report) => [...acc, ...Object.values(report.resources)], []);

    return resourceList;
};

const getRenderResults = (reports) => {
    const resourceList = getResources(reports);
    const renderResultsList = resourceList.reduce((acc, resource) => {
        const renderResults = resource?.renderResults?.renderResults;

        if (Array.isArray(renderResults)) {
            return [...acc, ...renderResults];
        }

        return [...acc];
    }, []);

    return renderResultsList;
};

const getRequests = (reports) => {
    const renderResultsList = getRenderResults(reports);
    const requestList = renderResultsList.reduce((acc, renderResults) => {
        const requests = renderResults?.urlRenderData?.result?.data?.requests;

        if (Array.isArray(requests)) {
            return [...acc, ...requests];
        }

        return [...acc];
    }, []);

    return requestList;
};

const getCertificates = (reports) => {
    const renderResultsList = getRenderResults(reports);
    const certificateList = renderResultsList.reduce((acc, renderResults) => {
        const certificates = renderResults?.urlRenderData?.result?.lists?.certificates;

        if (Array.isArray(certificates)) {
            return [...acc, ...certificates];
        }

        return [...acc];
    }, []);
    const certificateData = certificateList.map((certificate) => {
        const validFrom = dayjs(certificate.validFrom * 1000).format('D MMM YYYY HH:mm');
        const validTo = dayjs(certificate.validTo * 1000).format('D MMM YYYY HH:mm');

        return {
            ...certificate,
            validFrom,
            validTo,
        };
    }, []);

    return certificateData;
};

const getPhishDetections = (reports) => {
    const renderResultsList = getRenderResults(reports);
    const phishDetectionList = renderResultsList.reduce((acc, renderResults) => {
        const phishDetection = renderResults?.urlRenderData?.result?.phishDetection;
        const phishDetectionState = renderResults?.urlRenderData?.phishDetectionState;

        if (phishDetection && Object.keys(phishDetection).length > 0 && typeof phishDetectionState === 'string') {
            return [...acc, { ...phishDetection, phishDetectionState }];
        }

        return [...acc];
    }, []);

    return phishDetectionList;
};

const getImages = (reports) => {
    const resourceList = getResources(reports);
    const renderResultsList = resourceList.reduce((acc, resource) => {
        const renderResults = resource?.renderResults;

        if (Array.isArray(renderResults)) {
            return [...acc, ...renderResults];
        }

        return [...acc];
    }, []);
    const imageList = renderResultsList.reduce((acc, renderResults) => {
        const base64 = renderResults?.base64;

        if (base64) {
            return [...acc, base64];
        }

        return [...acc];
    }, []);

    return imageList;
};

const getColumns = (obj) => {
    const object = obj ?? {};

    return Object.keys(object).reduce((acc, header) => (
        [
            ...acc,
            {
                header: `${header.replace('_', ' ')}:`,
                accessor: header,
            }
        ]
    ), []);
};

const getElementList = (array) => {
    if (Array.isArray(array) && array.length > 0) {
        return array.map(item => <>{item}<br /></>);
    }

    return [<>{'-'}</>];
};

const getDuration = (start, end) => {
    if (start && end) {
        const time = (end - start) * 1000;
        const duration = dayjs.duration(time).asMilliseconds().toFixed(0);

        return `${duration} ms`;
    }

    return '-';
};

const getRequestsDom = (data) => {
    return data.map((request) => {
        const url = request?.response?.response?.url;
        const type = request?.initiatorInfo?.type;
        const method = request?.request?.request?.method;
        const status = request?.response?.response?.status;
        const callFrames = request?.request?.initiator?.stack?.callFrames;
        const duration = getDuration(request?.request?.timestamp, request?.response?.timestamp);
        const size = fileSizeConvert(request?.response?.dataLength);
        const details = {
            url: <a href={url} rel='noopener noreferrer' target='_blank'>{url}</a>,
            method: request?.response?.response?.protocol ?? '-',
            type: request?.response?.response?.mimeType ?? '-',
            time: duration,
            size,
        };
        const title = (
            <div className='expandableRow'>
                <span key={status} className={`badge ${getBadgeColor(status)}`}>
                    {status}
                </span>
                <span className='expandableRowTitle'>
                    {url}
                </span>
                {callFrames && `(${callFrames.length})`}
                <CopyButton icon='clone' data={url} />
            </div>
        );

        const requestHeader = request?.request?.request?.headers;
        const requestHeaderColumns = getColumns(requestHeader);

        const responseHeader = request?.response?.response?.headers;
        const responseHeaderColumns = getColumns(responseHeader);

        const securityData = request?.response?.response?.securityDetails;
        const sanList = getElementList(securityData?.sanList);
        const signedCertificateTimestampList = getElementList(securityData?.signedCertificateTimestampList);
        const validFrom = securityData?.validFrom ? dayjs(securityData?.validFrom * 1000).format('D MMM YYYY HH:mm') : '-';
        const validTo = securityData?.validTo ? dayjs(securityData?.validTo * 1000).format('D MMM YYYY HH:mm') : '-';
        const security = {
            ...securityData,
            sanList,
            signedCertificateTimestampList,
            validFrom,
            validTo,
        };
        const securityColumns = getColumns(security);

        const geoIpData = request?.response?.geoip;
        const ll = getElementList(geoIpData?.ll);
        const geoIp = {
            ...geoIpData,
            ll,
        };
        const geoIpColumns = getColumns(geoIp);

        const geoAsn = request?.response?.asn;
        const geoAsnColumns = getColumns(geoAsn);

        const expandableRow = (
            <div className='expandable columns'>
                <FileDetailsSection
                    data={details}
                    columnsData={requestColumns}
                />
                <Tabs id='tab-details' className='mb-3'>
                    {(requestHeader || responseHeader) && <Tab eventKey='headers' title='Headers' key='headers'>
                        <div className='columns'>
                            {requestHeader && <FileDetailsSection
                                title='Request'
                                data={requestHeader}
                                columnsData={requestHeaderColumns}
                            />}
                            {responseHeader && <FileDetailsSection
                                title='Response'
                                data={responseHeader}
                                columnsData={responseHeaderColumns}
                            />}
                        </div>
                    </Tab>}
                    {securityData && <Tab eventKey='security' title='Security' key='security'>
                        <FileDetailsSection
                            title='Security'
                            data={security}
                            columnsData={securityColumns}
                        />
                    </Tab>}
                    {(geoIpData || geoAsn) && <Tab eventKey='geolocation' title='Geolocation' key='geolocation'>
                        <div className='columns'>
                            {geoIpData && <FileDetailsSection
                                title='Geo IP'
                                data={geoIp}
                                columnsData={geoIpColumns}
                            />}
                            {geoAsn && <FileDetailsSection
                                title='ASN'
                                data={geoAsn}
                                columnsData={geoAsnColumns}
                            />}
                        </div>
                    </Tab>}
                </Tabs>
            </div>
        );

        const headerFields = [
            <span className='badge' key={type}>
                {type}
            </span>,
            <span className='badge' key={method}>
                {method}
            </span>,
        ];

        return (
            <ExpandableRow
                key={url}
                title={title}
                tableDom={expandableRow}
                titleSize='md'
                className='mt-5'
                extraHeaderFields={headerFields}
            />
        );
    });
};

const AddressAnalysis = ({ addressAnalysis, fileImage }) => {
    const { t } = useTranslation();

    const scoreDom = useMemo(() => {
        const threatLevel = addressAnalysis?.final_verdict?.threatLevel;
        const score = formatScore(threatLevel);
        const color = getScoreColor(threatLevel);
        const verdict = addressAnalysis?.final_verdict?.verdict?.replace('_', ' ');

        return (
            <ScoreHeader
                info={addressAnalysis?.scan_results?.scan_all_result_a === 'Aborted' && 'No sandbox performed'}
                isProcessing={(addressAnalysis.isLoading || (Array.isArray(addressAnalysis?.reports) && addressAnalysis.reports.length === 0 && addressAnalysis?.scan_results?.scan_all_result_a != 'Aborted'))}
                category={t('Sandbox Score')}
                score={score}
                badge={verdict}
                badgeColor={color}
                scoreColor={color}
                processImgSrc={fileImage.processing.publicURL}
                isPercentage={true}
            />
        );
    }, [addressAnalysis, fileImage, t]);

    const aboutDom = (
        /*eslint-disable-next-line no-useless-escape*/
        <p dangerouslySetInnerHTML={{ __html: t('Please read our <a href=\"https://www.opswat.com/products/metadefender/sandbox\" rel=\"noopener noreferrer\" target=\"_blank\">documentation</a> for details about interpreting the results.') }} />
    );

    const placeholders = useMemo(() => {
        const ROW_NUMBER = 5;
        const ROW_SPACE = 10;
        const ROW_HEIGHT = 30;
        let keyCounter = 0;

        return (
            <ContentLoader
                className={'contentLoader'}
                speed={1}
                height={ROW_NUMBER * (30 + ROW_SPACE)}
                width={'100%'}
                primaryColor="#EDEEF3"
                secondaryColor="#f3f3f3"
            >
                {Array(5)
                    .fill('')
                    .map((_value, index) => (
                        <rect
                            key={keyCounter++}
                            x="0"
                            y={index * (ROW_HEIGHT + ROW_SPACE)}
                            rx="4"
                            ry="4"
                            width='100%'
                            height={ROW_HEIGHT}
                        />
                    ))}
            </ContentLoader>
        );
    }, []);

    const imageDom = useMemo(() => {
        if (addressAnalysis.isLoading || (Array.isArray(addressAnalysis?.reports) && addressAnalysis.reports.length === 0 && addressAnalysis?.scan_results?.scan_all_result_a != 'Aborted')) {
            return placeholders;
        }

        const phishDetectionList = getPhishDetections(addressAnalysis?.reports);
        const phishDetection = phishDetectionList.shift();
        const predictImageData = phishDetection?.predict;
        const imageList = getImages(addressAnalysis?.reports);
        const imageData = imageList.shift();

        if (imageData) {
            return (
                <>
                    <Image base64={imageData} />
                    <div className='details'>
                        <span>Screenshots:</span>
                        <Image base64={imageData} label='Original' variant='url' />
                        {predictImageData && <Image base64={predictImageData} label='Phising' variant='url' />}
                    </div>
                </>
            );
        }
    }, [addressAnalysis]);

    const tabsDom = useMemo(() => {
        if (addressAnalysis.isLoading || (Array.isArray(addressAnalysis?.reports) && addressAnalysis.reports.length === 0 && addressAnalysis?.scan_results?.scan_all_result_a != 'Aborted')) {
            return placeholders;
        }

        const requestList = getRequests(addressAnalysis?.reports);
        const requestData = requestList.filter(req => Boolean(req?.response?.response?.url));
        const hasRequests = requestData.length > 0;
        const requestListDom = getRequestsDom(requestData);

        const certificateData = getCertificates(addressAnalysis?.reports);
        const hasCertificates = certificateData.length > 0;

        return (
            <Tabs id="tab" className="mb-3">
                {hasRequests && (
                    <Tab eventKey='requests' title={`Requests (${requestData.length})`} key='requests'>
                        {requestListDom}
                    </Tab>
                )}
                {hasCertificates && (
                    <Tab eventKey='certificates' title={`Certificates (${certificateData.length})`} key='certificates'>
                        <PlainTable
                            columnsData={certificateColumns}
                            data={certificateData}
                        />
                    </Tab>
                )}
            </Tabs>
        );
    }, [addressAnalysis]);

    return <div className='analysis'>
        <div className='analysisBody'>
            <Row>
                <Col lg={3} md={3} xs={12}>
                    {scoreDom}
                    {aboutDom}
                </Col>
                <Col lg={9} md={9} xs={12} className='detailsSection'>
                    {imageDom}
                </Col>
            </Row>
            {tabsDom}
        </div>
    </div>;
};

AddressAnalysis.propTypes = {
    addressAnalysis: PropTypes.object,
    fileImage: PropTypes.object.isRequired,
};

export default AddressAnalysis;
